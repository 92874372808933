.chat-bubble {
    position: relative;
    display: inline-block;
    border-radius: 6px;
    padding: 8px 15px;
    margin: 5px;
    max-width: 50%;
    text-align: left;
}

.receiver {
    background-color: #A0AEC0;
    color: #fff;
}

.sender {
    background-color: #319795;
    color: #fff;
    margin-left: auto;
}

.chat-arrow {
    position: absolute;
    bottom: 5px;
}

.receiver .chat-arrow {
    left: -10px;
}

.sender .chat-arrow {
    right: -10px;
    transform: scaleX(-1);
}

.chat-content {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 16px;
    word-wrap: break-word;
}

.sender .chat-content {
    color: #fff;
}
